import React from 'react'
import Serv1 from './Img/new3.jpg';
import Serv2 from './Img/new2.jpg';
import Serv3 from './Img/new4.jpg';
import Serv4 from './Img/new5.jpg';
import Serv5 from './Img/new7.jpg';
import Serv6 from './Img/new8.jpg';
import Footer from '../Footer';

const Gallery = () => {
  return (
    <>

            <div className='Service'>

                <div className='Serve'>
                    <img src={Serv1} />
                </div>

                <div className='Serve'>
                <img src={Serv2} />
                </div>

            </div>



            <div className='Service'>


                <div className='Serve'>
                <img src={Serv3} />
                </div>


                <div className='Serve'>
                    <img src={Serv4} />
                </div>



            </div>

            <div className='Service'>

                <div className='Serve'>
                    <img src={Serv5} />
                </div>

                <div className='Serve'>
                <img src={Serv6} />
                </div>

            </div>


            <Footer />
        </>

  )
}

export default Gallery