import React from 'react'
import Logo from '../Icons/new9.jpg'

const Darivate = () => {
    return (

        <div>
            <center className='our'><h1>Our Execution Specialization</h1></center>
            <br />
            <div className='derivate'>

                <div className='derivate2'>
                    <img src={Logo} />
                </div>

                <div className='derivate2'>
                    <h1>DESTINATION WEDDING CATERING</h1>
                    <p>One of the most important factors of a memorable destination wedding party is the food.
                        Here at the Pandey Ji  Caterers, we believe in spoiling guests with the choice.
                        Pick from a range of cuisines, and we'll put together a feast worthy of a king!
                        The chefs strive for perfection and innovation when it comes to your food. So,
                        whether it's a welcome breakfast or the baraat, a cocktail party, wedding feast,
                        or a reception gala, we offer a menu fitting the occasion along with friendly,
                        efficient services.</p>
                </div>

            </div>
        </div>
    )
}

export default Darivate