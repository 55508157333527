import React from 'react'

import About from './Pages/Img/About.jpg';

const Section = () => {
  return (
    <div className='section'>



      <h1> OUR PHILOSOPHY</h1>
      <h2> PANDEY JI CATERING  CROCKERY CUTLERY & SERVICE </h2>
      <p>Proper Arrangement Of full & Semi Catering  </p>
      <p>  WE PANDEY JI   CATERERS are a full-service catering company specialized only in vegetarian<br /> delicacies, comprising a unique presentation, remarkable taste & innovative event styling.<br /> We keep introducing fresh and new vegetarian menu ideas to accomplish the needs of its diverse <br />range of customers. We stand in a distinguished light for the catering service.

      </p>




      <div class="icon-bar">
        
        <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
        <a href="#" class="twitter"><i class="fa fa-instagram"></i></a>

      </div>
    </div>



  )
}

export default Section