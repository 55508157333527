import React from 'react'
import Logo from '../Icons/Logo2.png'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='foot'>
                <br />
                <img src={Logo} style={{ height: '65px' }} />
                <br />
                <hr />
                <br /><br />
                <p>We The Pandey Ji  Caterers are a full-service catering company specialized only in vegetarian delicacies,
                    comprising a unique presentation, remarkable taste & innovative event styling.</p>
            </div>

            <div className='foot'>
                <h1>FOLLOW US
                </h1>
                <br />
                <hr />

                <br /><br />
                <a href='' ><i class="fa fa-facebook-f"></i> Facebook</a><br />
                
                <a href=''><i class="fa fa-instagram"></i> Instagram</a><br />

                <a href='' style={{fontSize:'15px'}}><i class="fa fa-envelope"></i> jtndrapandey75@gmail.com</a><br />
                
                <p> Plot No-38 Near Om Namah Ashram Jagpal Kheda Vigyan  Khand Gomati Nagar Lucknow </p>

            </div>




            <div className='foot'>
                <h1>QUCIK LINKS
                </h1>
                <br />
                <hr />
                <br /><br/>
                <a href='https://wa.me/8423088105'><i class="fa fa-whatsapp"></i> 8423088105</a><br />

                <a href='Tel:8423088105'><i class="fa fa-phone"></i> 8423088105</a><br />

               
                
                <a href='about'>About</a><br />
                <a href='Feedback'>Contact</a><br />
                <a href=''>Service</a><br />
                <a href=''>Gallery</a><br/>
                <p>Designed By Creative Digital Word</p>

               

            </div>

        </div>
    )
}

export default Footer