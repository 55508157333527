import React from 'react'

import Course from '../Video/course-video.mp4';
import Earth from './Pages/Img/r5.jpg';
import Data1 from './Pages/Img/new8.jpg'
import Data2 from './Pages/Img/r6.jpg'
import Data3 from './Pages/Img/new7.jpg'


const Question = () => {
  return (


    <div className='video'>

      <div className='videos'>
      <img src={Data1} width="100%" height="100%"/>
      </div>

      <div className='videos'>
        <img src={Data3} width="100%" height="100%"/>
      </div>


    </div>
  )
}

export default Question