import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import Logo1 from '../Icons/logo (1).png';
import Dtad from '../Icons/Logo2.png';

import { CodeIcon, HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";




const DropdownMenu = ({ isOpen, handleClick }) => {

  return (
    
    <ul className={isOpen ? "dropdown-menu active" : "dropdown-menu"}>
      <li className="nav-item">
        <NavLink
          exact
          to="/blog"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          About Summit
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          exact
          to="/Feedback"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          Feedback
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          exact
          to="/"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          More Tools
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          exact
          to="/"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          Tabs
        </NavLink>
      </li>
    </ul>
  );
};

const MediaDropdownMenu = ({ isOpen, handleClick }) => {
  return (
    <ul className={isOpen ? "dropdown-menu active" : "dropdown-menu"}>
      <li className="nav-item">
        <NavLink
          exact
          to="/Gallery"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          Photos
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          exact
          to="/"
          activeClassName="active"
          className="nav-links"
          onClick={handleClick}
        >
          Videos
        </NavLink>
      </li>
    </ul>
  );
};

function NavBar() {
  const [click, setClick] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mediaDropdownOpen, setMediaDropdownOpen] = useState(false);

  const handleClick = () => {
    setClick(!click);
    setDropdownOpen(false);
    setMediaDropdownOpen(false);
  }

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  }

  const handleMediaDropdownClick = () => {
    setMediaDropdownOpen(!mediaDropdownOpen);
  }

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <span>
              <img src={Dtad} style={{paddingTop:'05px'}} />
            </span>
            {/* <i className="fas fa-code"></i> */}
            <span className="icon"></span>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            {/* <li className="nav-item" onClick={handleDropdownClick}>
              <span className="nav-links">
                Service <i class="fa fa-caret-down" aria-hidden="true"></i>
              </span>
              <DropdownMenu isOpen={dropdownOpen} handleClick={handleClick} />
            </li> */}
            

            <li className="nav-item">
              <NavLink
                exact
                to="/Service"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Service
              </NavLink>
            </li>



            <li className="nav-item">
              <NavLink
                exact
                to="/Feedback"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>



            <li className="nav-item" onClick={handleMediaDropdownClick}>
              <span className="nav-links">
                Media <i class="fa fa-caret-down" aria-hidden="true"></i>
              </span>
              <MediaDropdownMenu
                isOpen={mediaDropdownOpen}
                handleClick={handleClick}
              />
            </li>


            {/* <li className="nav-item">
              <NavLink
                exact
                to="/Register"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Register
              </NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink
                exact
                to="/Feedback"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Feedback
              </NavLink>
            </li> */}
          </ul>

          <div className="nav-icon" onClick={handleClick}>

            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
                <HamburgetMenuClose />
              </span>
            ) : (

              <span className="icon">
                <HamburgetMenuOpen />{" "}
              </span>
            )}

          </div>

        </div>
      </nav>
    </>
  );
}

export default NavBar;
