import React from 'react'
import Video from "../Video/vid10.mp4";




const Header = () => {
  return (
    <div className='header'>
    <video className='header-video' controls autoPlay loop>
      <source src={Video} type='video/mp4' />
      
    </video>
  </div>
  )
}

export default Header