import React from 'react'
import Footer from './Footer';
import Serv1 from './Pages/Img/new1.jpg';
import Serv2 from './Pages/Img/new7.jpg';
import Serv3 from './Pages/Img/new9.jpg';
import Serv4 from './Pages/Img/Ser4.jpeg';
import Serv5 from './Pages/Img/Ser5.jpeg';
import Serv6 from './Pages/Img/Ser6.jpeg';
import Serv7 from './Pages/Img/Ser7.jpeg';
import Serv8 from './Pages/Img/Ser8.jpeg';


const Service = () => {
    return (
        <>

            <div className='Service'>

                <div className='Serve'>
                    <img src={Serv1} />
                </div>

                <div className='Serve'>
                    <h1>Catering</h1>
                    <p>We are the Well Know Name in Catering  Service </p>
                    <a href=''>  <button>Book Now </button></a>
                </div>

            </div>



            <div className='Service'>


                <div className='Serve'>
                    <h1>Baverage Cafeteria </h1>
                    <p>We are the best in mocktail</p>
                    <a href=''>  <button>Book Now </button></a>
                </div>


                <div className='Serve'>
                    <img src={Serv2} />
                </div>



            </div>

            <div className='Service'>

                <div className='Serve'>
                    <img src={Serv3} />
                </div>

                <div className='Serve'>
                    <h1>Cocktails</h1>
                    <p>We have a huge variety of cocktail  </p>
                    <a href=''>  <button>Book Now </button></a>
                </div>

            </div>


            <Footer />
        </>
    )
}

export default Service