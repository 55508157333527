import { useState } from "react";
import Footer from '../Footer';

const Feedback = () => {
  const [inpval, setINP] = useState({
    name: "",
    email: "",
    contact: "",
    Designation: "",
    menu: "veg", // Default to vegetarian
    gathering: "",
    date: "",
  });

  const setdata = (e) => {
    const { name, value } = e.target;
    setINP((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
  };

  const addFeedback = async (e) => {
    e.preventDefault();

    const { name, email, contact, Designation, menu, gathering, date } = inpval;

    if (name === "" || email === "" || contact === "" || Designation === "" || gathering === "" || date === "") {
      alert("Please fill in all the required fields.");
      return;
    }

    // You can replace the URL with the actual endpoint for submitting feedback
    const res = await fetch("/submit-feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        contact,
        Designation,
        menu,
        gathering,
        date,
      }),
    });

    const data = await res.json();

    if (!data || res.status === 404) {
      alert("Error: Feedback not submitted.");
    } else {
      alert("Feedback Submitted");
    }
  };

  return (
    <>
      <div className="describe">
        <center>
          <h1>Contact</h1>
        </center>
        <form onSubmit={addFeedback}>
          <label>Name</label>
          <input type="text" value={inpval.name} onChange={setdata} name="name" id="done" />

          <label>Email</label>
          <input type="text" value={inpval.email} onChange={setdata} name="email" id="done" />

          <label>Contact</label>
          <input type="text" value={inpval.contact} onChange={setdata} name="contact" />

          <label>Designation</label>
          <input type="text" value={inpval.Designation} onChange={setdata} name="Designation" />

          <label>Select Menu</label>
          <select value={inpval.menu} onChange={setdata} name="menu">
            <option value="veg">Vegetarian</option>
            <option value="nonveg">Non-Vegetarian</option>
          </select>

          <label>Number of Guests</label>
          <input type="number" value={inpval.gathering} onChange={setdata} name="gathering" />

          <label>Select Date</label>
          <input type="date" value={inpval.date} onChange={setdata} name="date" />

          <input type="submit" value="Submit" />
        </form>

        <Footer />
      </div>
    </>
  );
};

export default Feedback;
