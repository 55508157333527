import React from 'react';
import Gal1 from './Pages/Img/new1.jpg';
import Gal2 from './Pages/Img/new2.jpg';
import Gal3 from './Pages/Img/new3.jpg';
import Gal4 from './Pages/Img/new4.jpg';
import Gal5 from './Pages/Img/new7.jpg';
import Gal6 from './Pages/Img/new8.jpg';

const Gallhome = () => {
    return (

        <div>
            <br />

            <center style={{ color: 'gold' }}><h1>Our Gallery</h1></center>
            <br />

            <div className="image-container">

                <img src={Gal1} alt="Gallery 1" className="responsive-image" />
                <img src={Gal2} alt="Gallery 2" className="responsive-image" />
                <img src={Gal3} alt="Gallery 3" className="responsive-image" />
                <img src={Gal4} alt="Gallery 4" className="responsive-image" />
                <img src={Gal6} alt="Gallery 6" className="responsive-image" />
                <img src={Gal5} alt="Gallery 5" className="responsive-image" />
            </div>

        </div>
    );
}

export default Gallhome;
