import React, { useState } from 'react';
import Footer from '../Footer';

export const Contact = () => {
  const [inpval, setINP] = useState({
    email: "",
    contact: "",
    detail: "",
    date: "",
    guests: "",
    menu: "veg",
  });

  const setdata = (e) => {
    const { name, value } = e.target;
    setINP((preval) => {
      return {
        ...preval,
        [name]: value
      };
    });
  }

  const addinpdata = async (e) => {
    e.preventDefault();

    const { email, contact, detail, date, guests, menu } = inpval;

    if (email === "" || contact === "" || detail === "") {
      alert("Please fill in all the required fields.");
      return;
    }

    const res = await fetch("/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email, contact, detail, date, guests, menu
      })
    });

    const data = await res.json();

    if (!data || res.status === 404) {
      alert("Error: Data not saved.");
    } else {
      alert("Data Saved");
    }
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row conten'>
          <div className='col-sm-12'>
            <form method='POST'>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Enter Your Email Address</label>
                <input type="email" className="form-control" value={inpval.email} onChange={setdata} name="email" aria-describedby="emailHelp" placeholder="Enter email" />
                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Enter Your Contact Number</label>
                <input type="text" className="form-control" value={inpval.contact} onChange={setdata} name="contact" placeholder="Contact Number" />
              </div>

              <div className="form-group form-check">
                <label htmlFor="exampleInputPassword1">Enter Your Details</label>
                <input type="text" className="form-control" value={inpval.detail} onChange={setdata} name="detail" placeholder="Detail" />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputDate">Select Date</label>
                <input type="date" className="form-control" value={inpval.date} onChange={setdata} name="date" />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputGuests">Number of Guests</label>
                <input type="number" className="form-control" value={inpval.guests} onChange={setdata} name="guests" />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputMenu">Select Menu</label>
                <select className="form-control" value={inpval.menu} onChange={setdata} name="menu">
                  <option value="veg">Vegetarian</option>
                  <option value="nonveg">Non-Vegetarian</option>
                </select>
              </div>

              <button type="submit" onClick={addinpdata} className="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Contact;
