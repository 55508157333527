import React from "react";
import Footer from '../Footer';
import Section from "../Section";


export const About = () => {
  return (
    <>

      <div className='about'>
<h1>Our story<br/>
WE VALUE SERVICE TASTE AND STYLE</h1>
<h1>Home / About </h1>
      </div>

      <Section />
      <Footer />

    </>
  );
};
